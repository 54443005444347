<template>
  <div class="container-xs">
    <!-- Package search -->
    <div
      style="
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 1rem;
      "
    >
      <IconLogo width="42px" height="100%" />
      <div>
        <p style="font-size: 25px" class="search-title">
          Discover where you can go
        </p>
        <p style="font-family: NotoSans; font-size: 18px">
          Let us help you get there
        </p>
      </div>
    </div>
    <button
      class="search-card"
      style="height: 300px; width: 100%; margin-top: 1rem"
      @click="
        selectType({
          searchType: cards.main.searchType,
          trackId: cards.main.trackId,
          budget: 800,
        })
      "
    >
      <picture>
        <source
          type="image/webp"
          :srcset="cards.main.image.webp"
          :alt="cards.main.alt"
        />
        <img
          class="search-card-image"
          fetchpriority="high"
          :src="cards.main.image.jpg"
          :alt="cards.main.alt"
        />
      </picture>
      <div class="search-card-image-bg"></div>
      <div
        class="search-card-text"
        style="position: absolute; bottom: 0; color: white"
      >
        <p
          style="
            font-size: 26px;
            font-family: SFPro;
            font-weight: 800;
            width: 60%;
          "
        >
          Where could you go for $800?
        </p>
        <XButton
          color="ternary"
          type="medium"
          arrow
          style="
            font-family: SFPro;
            font-size: 18px;
            font-weight: 600;
            text-transform: initial;
            letter-spacing: initial;
            margin-top: 1rem;
          "
          >Book based on budget</XButton
        >
      </div>
    </button>
    <!-- Trending destinations -->
    <p
      v-if="searchFeaturedLocations.length"
      class="search-title"
      style="margin-top: 2rem"
    >
      Trending Destinations
    </p>
    <div
      v-if="searchFeaturedLocations.length"
      class="search-featured-destinations"
    >
      <button
        v-for="(featuredLocation, index) in searchFeaturedLocations"
        :key="`featured-location-${index}`"
        class="search-card"
        :style="featuredLocation.style"
        @click="selectFeaturedLocation(featuredLocation)"
      >
        <div style="height: 100%; width: 100%; overflow: hidden">
          <img class="search-card-image" :src="featuredLocation.img" />
        </div>
        <div class="search-card-image-bg"></div>
        <div
          style="
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <p
            style="
              font-size: 18px;
              font-family: SFPro;
              font-weight: 800;
              color: white;
            "
          >
            {{ featuredLocation.name }}
          </p>
        </div>
      </button>
    </div>
    <!-- Dream trip -->
    <p class="search-title" style="margin-top: 2rem">Build your dream trip</p>
    <p style="font-family: NotoSans; font-size: 16px">
      Personalize your trip experience.
    </p>
    <div style="margin-top: 1rem; display: grid; gap: 1rem">
      <button
        v-for="card in cards.alt"
        :key="card.searchType"
        class="search-card"
        style="height: 160px"
        @click="
          selectType({ searchType: card.searchType, trackId: card.trackId })
        "
      >
        <picture>
          <source type="image/webp" :srcset="card.image.webp" :alt="card.alt" />
          <img
            class="search-card-image"
            :src="card.image.jpg"
            :alt="card.alt"
          />
        </picture>
        <div class="search-card-image-bg"></div>
        <div
          class="search-card-text"
          style="
            position: absolute;
            bottom: 0;
            color: white;
            padding-bottom: 12px;
          "
        >
          <p class="search-card-title">
            {{ card.title }}
          </p>
          <p class="search-card-caption">{{ card.caption }}</p>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import comboJpg from '@/assets/images/search-screen/combo.jpg'
import comboWebp from '@/assets/images/search-screen/combo.webp'
import flightJpg from '@/assets/images/search-screen/flight.jpg'
import flightWebp from '@/assets/images/search-screen/flight.webp'
import hotelJpg from '@/assets/images/search-screen/hotel.jpg'
import hotelWebp from '@/assets/images/search-screen/hotel.webp'
import packageJpg from '@/assets/images/search-screen/package.jpg'
import packageWebp from '@/assets/images/search-screen/package.webp'
import { mapActions, mapMutations, mapState } from 'vuex'
import IconLogo from '../../components-rf/icons/branding/IconLogo.vue'
import XButton from '../../components/common/global/XButton.vue'
import { SEARCH } from '../../constants/SEARCH'
import { appAnalytics } from '../../services/analytics/analytics'

const images = {
  combo: {
    webp: comboWebp,
    jpg: comboJpg,
  },
  hotel: {
    webp: hotelWebp,
    jpg: hotelJpg,
  },
  flight: {
    webp: flightWebp,
    jpg: flightJpg,
  },
  package: {
    webp: packageWebp,
    jpg: packageJpg,
  },
}

export default {
  name: 'SearchScreen',
  components: { IconLogo, XButton },
  mounted() {
    appAnalytics.page({ title: 'search_screen_view' })
  },
  created() {
    if (!this.featuredLocations.length) this.getFeaturedLocations()
  },
  data() {
    return {
      SEARCH,
      cards: {
        main: {
          image: images.package,
          searchType: SEARCH.TYPE.PACKAGE,
          trackId: 'search_screen_view_btn_package',
          alt: 'search recommended itineraries',
          title: 'Elude Package',
          caption:
            "You set your budget and date, and we'll build a flight & hotel package just for you.",
        },
        alt: [
          {
            image: images.combo,
            searchType: SEARCH.TYPE.COMBO,
            trackId: 'search_screen_view_btn_flight_and_hotel',
            alt: 'search flights and hotels',
            title: 'Flights & Hotel',
            caption:
              'Have a destination in mind? Search our flight and hotel bundles.',
          },
          {
            image: images.hotel,
            searchType: SEARCH.TYPE.HOTEL,
            trackId: 'search_screen_view_btn_hotel',
            alt: 'search hotels',
            title: 'Hotel',
            caption: 'No need for a flight? Search our hotel-only options.',
          },
          {
            image: images.flight,
            searchType: SEARCH.TYPE.FLIGHT,
            trackId: 'search_screen_view_btn_flight',
            alt: 'search flights',
            title: 'Flight',
            caption:
              "You're making it easy for us. Search our flight-only options.",
          },
        ],
      },
    }
  },
  computed: {
    ...mapState('LocationModule', ['featuredLocations']),
    searchFeaturedLocations() {
      return this.featuredLocations.slice(0, 3).map((featured, index) => {
        const location = featured.arrivalLocation
        return {
          style: {
            gridArea: `featured-${index}`,
          },
          id: location.id,
          trackId: 'search_screen_view_btn_featured_location',
          img: location.imageUrl,
          name: location.metadata?.cityName || location.displayName,
          location,
        }
      })
    },
  },
  methods: {
    ...mapMutations('SearchForm', ['setParams']),
    ...mapActions('LocationModule', ['getFeaturedLocations']),
    selectType({ searchType, trackId, budget }) {
      appAnalytics.track(trackId)
      const params = { searchType }
      if (budget) params.budget = 800
      this.setParams(params)
      this.$router.push('/search/form')
    },
    selectFeaturedLocation(loc) {
      const location = loc.location
      appAnalytics.track(loc.trackId)
      const searchParams = {
        searchType: SEARCH.TYPE.COMBO,
        destination: location.id,
        destinationLocation: location,
      }
      this.setParams(searchParams)
      this.$router.push('/search/form')
    },
  },
}
</script>

<style scoped lang="scss">
.search-title {
  font-size: 22px;
  font-family: SFPro;
  font-weight: 600;
  line-height: 1.2;
}
.search-card {
  border-radius: 9px;
  background: #f5f5f5;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-shadow: 1px 3px 3px #0004;
  transition: box-shadow 0.2s ease-in-out;
  @media (hover: hover) {
    &:hover,
    &:focus {
      box-shadow: 1px 2px 2px #0003;
      .search-card-image {
        transform: scale(1.02);
      }
      .search-card-image-bg {
        background: #0006;
      }
    }
  }
}
.search-featured-destinations {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1.5fr 1fr;
  grid-template-areas: 'featured-0 featured-1' 'featured-0 featured-2';
  gap: 1rem;
  margin-top: 1rem;
  height: 350px;
}
.search-card-image {
  width: 100%;
  height: 100%;
  object-position: top;
  transition: transform 0.5s ease;
}
.search-card-image-bg {
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  transition: background 0.5s ease;
  background: #0004;
}
.search-card-text {
  width: 100%;
  text-align: start;
  padding: 1.5rem;
  @include s {
    padding: 1.75rem;
  }
}
.search-card-title {
  font-family: SFPro;
  font-size: 20px;
  font-weight: bold;
}
.search-card-caption {
  margin-top: 6px;
  font-family: NotoSans;
  font-size: 13px;
}
</style>
